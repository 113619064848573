@import './src/styles/_global.scss';
.searchInput {
	display: flex;
	input {
		border: none;
		outline: none;
		flex: 1;
		border-bottom: 2px solid #02355c;
		appearance: none;
		background: none;
		box-shadow: none;
		border-radius: 0;
	}
}

.glyph {
	width: var(--search-input-glyph-size, 63px);
	height: var(--search-input-glyph-size, 63px);
	fill: #02355c;
	margin-right: calc(var(--search-input-glyph-size, 63px) / 2);
	flex-shrink: 0;
}
