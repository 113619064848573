@import './src/styles/_global.scss';
.iconText {
	display: flex;
	align-items: center;
	.icon {
		width: 24px;
		margin-right: 10px;
		svg {
			display: block;
			margin: 0 auto;
			fill: #02355c;
		}
	}
}

.contact {
	display: flex;
	&.stacked {
		flex-direction: column;
	}
	&.inline {
		> * + * {
			margin-left: 1rem;
		}
	}
}
