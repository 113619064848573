@import './src/styles/_global.scss';
.headlineNav {
	display: flex;
	&.bordered {
		border-bottom: 2px solid $color-gold-dark;
		padding-bottom: 1rem;
	}
	.nav {
		display: flex;
		flex: 2;
		align-items: flex-end;
		justify-content: flex-end;
		border-bottom: 2px solid $color-gold-dark;
		margin-left: $gutter;
		a {
			font-size: 1.6rem;
			font-weight: 500;
		}
	}
}
