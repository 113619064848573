@import './src/styles/_global.scss';
.searchWrapper {
	background-color: #f1f1f2;
	padding: 4rem 0;
	.inner {
		margin-top: 2rem;
		.atoz {
			flex: 1;
			padding-left: 2rem;
			padding-right: 2rem;
		}
		.form {
			flex: 1.5;
		}
		&.inline {
			display: flex;
		}
		&.stacked {
			.atoz {
				margin-top: 2rem;
			}
		}
	}
}

.field {
	--placeholder: #75757c;
	--accent: #d2d2d4;
	&,
	input {
		font-size: 1.5rem;
	}
	.input {
		width: 100%;
		background: #fff;
		border: 1px solid var(--accent);
		padding: 8px 12px;
		&::placeholder {
			color: var(--placeholder);
		}
	}
}

.field + .field {
	margin-top: 1rem;
}
