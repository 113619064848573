@import './src/styles/_global.scss';
.location {
	--link-color: $color-body;

	img {
		display: block;
		width: 100%;
		margin-bottom: 1rem;
	}
}

.address {
	font-style: normal;
	line-height: 1.33;
	margin-top: 1.5rem;
}

.contactLinks {
	margin: 1.2rem 0 0;
	padding: 0;
	list-style: none;
	li {
		display: flex;
	}
	li + li {
		margin-top: 0.5rem;
	}
	.icon {
		margin-right: 1rem;
	}
}
