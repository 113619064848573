@import './src/styles/_global.scss';
.attorney {
	.headline {
		font-size: 2.4rem;
		line-height: 1;
	}

	.rank {
		margin-top: 0.5rem;
	}

	.rank {
		font-size: 2rem;
		line-height: 1;
	}

	.name {
		margin-bottom: 1rem;
	}

	.lower {
		margin-top: 1rem;
	}

	.lower.inline {
		display: flex;
		.secondary {
			margin-left: auto;
		}
	}

	.lower.stacked {
		.secondary {
			margin-top: 0.5rem;
		}
	}
}
