@import './src/styles/_global.scss';
.select,
.inner {
	position: relative;
}

.label {
	min-height: 1em;
	&.placeholder {
		color: var(--placeholder);
	}
}

.downarrow {
	position: absolute;
	top: calc(50% + 1px);
	right: 0;
	transform: translateY(-50%);
}
.selectElement {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	appearance: none;
	cursor: pointer;
	font-size: 16px;
}
