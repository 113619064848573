@import './src/styles/_global.scss';
.container,
.collapse {
	max-width: 1200px;
	margin: 0 auto;
	padding-left: $gutter;
	padding-right: $gutter;
	position: relative;
	width: 100%;
}

.collapse {
	padding: 0;
}
