@import './src/styles/_global.scss';
.heroHeadline {
	color: #fff;
	max-width: 820px;
	margin: 0 auto;
	h1 {
		font-size: 8.4rem;
		line-height: 1;
		margin: 0 0 4rem 0;
		@media screen and (max-width: 819px) {
			font-size: 10vw;
			margin-bottom: 2.5rem;
		}
		strong {
			color: $color-gold-dark;
			text-transform: uppercase;
			font-size: 140%;
		}
		span {
			display: block;
		}
		span:last-child {
			text-align: right;
		}
	}
	.text {
		text-align: center;
		font-weight: 600;
		font-size: 3.6rem;
		line-height: 1.2;
		@media screen and (max-width: 819px) {
			font-size: 4vw;
			line-height: 1.5;
		}
		@media screen and (max-width: 580px) {
			font-size: 6vw;
		}
		p {
			margin: 0;
		}
		p + p {
			margin-top: 0.25em;
		}
	}
}
