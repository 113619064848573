@import './src/styles/_global.scss';
.nav {
	display: block;
}

.navUpper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media print {
		margin-bottom: 2rem;
	}
}

.navToggle {
	display: flex;
	width: 34px;
	height: 30px;
	cursor: pointer;
	border: none;
	background: none;
	padding: 0;
	align-items: center;
	justify-content: center;
	@media print {
		display: none !important;
	}
	svg {
		display: block;
		fill: #1e5a8e;
	}
}

.navList {
	--accent: #fff;
	display: flex;
	align-items: center;
	margin: 0 -1rem;
	padding: 0;
	list-style: none;
	@media print {
		display: none !important;
	}
	a {
		display: block;
		line-height: 1;
		font-weight: 500;
		transition: all 0.3s;
		color: #02355c;
		&:hover {
			text-decoration: underline;
		}
	}
	.primary {
		position: relative;
		> a {
			font-size: 1.5rem;
			border-bottom: 2px solid transparent;
			padding: 0.75rem 1rem;
			&:hover {
				color: #1e5a8e;
			}
			&.active {
				background: #1e5a8e;
				color: #fff;
				text-decoration: none;
			}
		}
		ul {
			display: none;
			margin: 0;
			padding: 0 0 0 1rem;
			list-style: none;
			position: absolute;
			top: 100%;
		}
		&:hover ul {
			display: block;
		}
	}
	.primary + .primary {
		margin-left: 1rem;
	}
	.secondary {
		> a {
			background: #f1f1f2;
			font-size: 1.4rem;
			white-space: nowrap;
			padding: 1rem 1.25rem;
		}
	}
	.secondary + .secondary {
		border-top: 2px solid #fff;
	}
}

.searchGlyph {
	display: block;
	width: 16px;
	height: 16px;
	fill: #02355c;
}

:global(.screen-small),
:global(.screen-medium) {
	.searchGlyph {
		width: 28px;
		height: 28px;
		fill: #1e5a8e;
	}
	.navList {
		margin-right: 0;
	}
}
