@import './src/styles/_global.scss';
.body {
	padding: 4rem 0;
}

.loading {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 25vh;
}

.layoutSection + .layoutSection {
	margin-top: 4rem;
}

.layoutSectionHeadline {
	margin-bottom: 1.5rem;
}
