@import './src/styles/_global.scss';
.logo {
	display: block;
	svg,
	img {
		display: block;
		height: 55px;
		width: auto;
	}
	&.small {
		svg,
		img {
			height: 35px;
		}
	}
}
