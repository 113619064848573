@import './src/styles/_global.scss';
.btn {
	font-family: $font-primary;
	width: 100%;
	display: inline-block;
	color: $color-blue;
	cursor: pointer;
	background-color: $color-btn-bg;
	font-size: 1.8rem;
	line-height: 1.22;
	font-weight: 600;
	text-align: left;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	padding: 0.9rem 1.3rem;
	border: none;
	border-bottom: 2px solid $color-btn-accent;

	&[disabled] {
		background-color: $color-grey;
		border: none;
		cursor: not-allowed;
	}
	&[type='secondary'] {
		color: $color-gold-dark;
		background-color: $color-white;
		border: 1px solid $color-blue;
	}

	&.large {
		padding: 0.7rem 3.2rem; //size sketch on homepage, not in style guide
		width: auto;
	}
}
