@import './src/styles/_global.scss';
.service {
	height: 100%;
	background-color: $color-grey;

	img {
		display: block;
		width: 100%;
	}

	.headline {
		font-size: 2.1rem;
		line-height: 1.2;
		font-weight: 300;
		font-family: $font-condensed;
		color: $color-blue;
		padding: 1.3rem 1rem 0.8rem;
		margin: 0;
		text-align: center;
	}

	.large {
		font-size: 2.6rem;
		font-weight: 400;
		padding-bottom: 3rem;
		text-align: left;
	}
}
