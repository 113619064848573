@import './src/styles/_global.scss';
.search {
	min-height: 200px;
	&__input {
		margin-bottom: 6rem;
	}
}

.spinner {
	display: flex;
	align-items: center;
	justify-content: center;
}

:global(.screen-small) {
	.search {
		--search-input-glyph-size: 32px;
		&__input {
			margin-bottom: 2rem;
		}
	}
}

.group__headline {
	margin-bottom: 2rem;
}
.group + .group {
	margin-top: 3rem;
}

.nav {
	display: flex;
	margin-top: -60px;
	margin-bottom: 60px;
	padding-left: calc(63px + 31.5px);
	button {
		text-align: left;
		font-size: 75%;
	}
	&__nodes {
		display: block;
		margin-left: auto;
		button {
			margin-left: 16px;
		}
	}
}
:global(.screen-small) {
	.nav {
		display: block;
		margin-top: 0;
		margin-bottom: 30px;
		padding-left: 0;
		&__nodes {
			display: flex;
			flex-wrap: wrap;
			button {
				margin: 0;
				width: 50%;
			}
		}
	}
}

.buttonLink {
	background: none;
	border: none;
	padding: 0;
	color: var(--link-color);
	outline: none;
	cursor: pointer;
	&.active {
		font-weight: 700;
	}
	&[disabled] {
		cursor: not-allowed;
	}
}
