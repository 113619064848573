@import './src/styles/_global.scss';
.wrapper {
	display: flex;
	flex-direction: column;
	flex: 1 0 0;
	@media print {
		display: block;
	}
}

.body {
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	& > :last-child {
		margin-top: auto;
	}
}
