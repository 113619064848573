@import './src/styles/_global.scss';
.masthead {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 5rem 0;
	position: relative;
	> * {
		z-index: 2;
	}
	&::before {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		content: '';
		background: linear-gradient(rgba(#02355c, 0.85), rgba(#056595, 0.65));
		z-index: 1;
	}
	&.large {
		padding: 12rem 0;
	}
	&.collapsed {
		// todo: would rather this not use a magic height
		padding: 0;
		height: 90px;
	}
}

.pageTitle {
	font-size: 8vw;
	display: block;
	width: 100%;
	margin: 0;
	text-align: center;
	color: $color-white;
	font-family: $font-condensed;
	font-weight: 400;
	line-height: 1.13;
	text-transform: uppercase;
}
.masthead.large {
	.pageTitle {
		font-size: 8rem;
	}
}
