@import './src/styles/_global.scss';
@mixin list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.footer {
	--link-color: #fff;
	background: $color-blue;
	padding: 4.5rem 0 3.5rem;
	color: $color-white;
	@media print {
		display: none !important;
	}
}

.upper {
	margin-bottom: 2.5rem;
}

.links {
	@include list;
	li {
		display: block;
		font-size: 1.6rem;
		line-height: 1.187;
		font-weight: 400;
		margin-bottom: 0.5rem;
	}
	a {
		color: #fff;
	}
}

.headline {
	font-size: 1.8rem;
	line-height: 1.22;
	font-weight: 500;
	margin: 0 0 0.5rem;
}

.socialLinks {
	@include list;
	display: flex;
	align-items: center;

	li + li {
		margin-left: 1.5rem;
	}
}

.mirrorList {
	display: flex;

	.links {
		width: 50%;
		&:first-of-type {
			text-align: right;
			padding-right: 1rem;
		}

		&:last-of-type {
			text-align: left;
			padding-left: 1rem;
		}
	}
}

.stackedSection {
	width: 100%;
	justify-content: center;

	.links {
		text-align: center;
	}
}

.stackedSection + .stackedSection {
	margin-top: 3rem;
	padding-top: 3rem;
	border-top: 1px solid #fff;
}

.copyright {
	font-size: 1.2rem;
	margin-top: 8rem;
}

// Stacked and Inline ----

.footer.stacked {
	.upper {
		text-align: center;
		a {
			display: inline-block;
		}
	}

	.body {
		flex-direction: column;
	}

	.socialLinks {
		margin-top: 3.5rem;
		justify-content: center;
	}

	.mirrorList {
		flex-wrap: wrap;
	}

	.headline {
		margin-bottom: 1.5rem;
		width: 100%;
		text-align: center;
	}
}

.footer.inline {
	.upper {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.navWrapper {
		display: flex;
		justify-content: space-between;
	}

	.navList {
		display: inline-flex;
	}

	.links + .links {
		margin-left: 4rem;
	}

	.copyright {
		width: 100%;
		margin-top: 14.5rem;
	}
}
