@import './src/styles/_global.scss';
.masthead {
	display: block;

	@media print {
		border-bottom: 2px solid $color-gold-dark;
		padding-bottom: 4rem;
	}

	.name {
		@media print {
			font-size: 40px !important;
		}
	}

	.rank {
		@media print {
			font-size: 28px !important;
		}
	}

	.contactPrimary {
		margin-top: 2rem;
	}

	.contactSecondary {
		margin-top: 0.5rem;
	}

	&.stacked {
	}

	&.inline {
		display: flex;
		.headshot {
			width: 275px;
			margin-right: 2.5rem;
		}
	}

	.printInfo {
		line-height: 1.4;
		margin-top: 1rem;
		.phonePrefix {
			display: inline-block;
			width: 1em;
		}
	}
}

.body {
	.bio {
		flex: 1;
	}
	.sidebar {
		background: #f1f1f2;
		padding: 1.5rem 1.5rem 2rem;
		@media print {
			margin: 0 2rem 0 2rem;
		}
		.section + .section {
			margin-top: 2.5rem;
		}
		.heading {
			margin: 0;
			font-size: 1.8rem;
			color: $color-gold-dark;
			text-transform: uppercase;
		}
		.list {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				font-size: 1.6rem;
				line-height: 1.125;
			}
			li + li {
				margin-top: 8px;
			}
		}
	}
	&.stacked {
		display: flex;
		flex-direction: column-reverse;
		.sidebar {
			margin-top: 3rem;
		}
	}
	&.inline {
		.sidebar {
			width: 280px;
		}
		@media screen {
			display: flex;
			flex-direction: row-reverse;
			.sidebar {
				flex-shrink: 0;
				margin-left: 3rem;
			}
		}
		@media print {
			.sidebar {
				float: right;
			}
		}
	}
}

.articles {
	margin-top: 3rem;
}

.screenOnly {
	@media print {
		display: none !important;
	}
}

.printOnly {
	@media screen {
		display: none !important;
	}
}
