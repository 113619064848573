@import './src/styles/_global.scss';
.inline {
	display: flex;
	& > * {
		flex: 1;
	}
	& > * + * {
		margin-left: 5rem;
	}
}

.stacked {
	display: block;
}
