@import './src/styles/_global.scss';
.wrapper {
	padding: 4rem 0;
}

.articles {
	margin-top: 4rem;
}

.spinner {
	display: flex;
	justify-content: center;
	align-items: center;
}
