@import './src/styles/_global.scss';
.headline {
	font-family: $font-condensed;
	font-weight: 300;
	color: $color-blue-dark;
	margin: 0;

	&.underline {
		border-bottom: 2px solid $color-gold-dark;
		padding-bottom: 1rem;
		margin-bottom: 2rem;
	}

	&[theme='hd-1'] {
		font-size: 3.8rem;
		line-height: 1.13;
		font-weight: 400;
	}

	&[theme='hd-2'] {
		font-size: 3.2rem;
		line-height: 1.09;
	}

	&[theme='hd-3'] {
		font-size: 2.6rem;
		line-height: 1.23;
	}

	&[theme='hd-4'] {
		font-size: 2.4rem;
		line-height: 1.16;
		color: $color-body;
	}

	/* Tablet */
	&.sizeMedium {
		&[theme='hd-1'] {
			font-size: 5.6rem;
			line-height: 1.178;
			font-weight: 300;
		}
	}

	/* Desktop */
	&.sizeLarge {
		&[theme='hd-1'] {
			font-size: 6rem;
			line-height: 1.25;
		}

		&[theme='hd-2'] {
			font-size: 3.6rem;
			line-height: 1.22;
		}

		&[theme='hd-3'] {
			font-size: 3.2rem;
			line-height: 1.25;
		}

		&[theme='hd-4'] {
			font-size: 2.6rem;
			line-height: 1.23;
		}

		&[theme='hd-1'],
		&[theme='hd-2'],
		&[theme='hd-3'],
		&[theme='hd-4'] {
			font-weight: 400;
		}
	}
}
