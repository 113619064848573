@import './src/styles/_global.scss';
$bgColor: #f1f1f2;

.attorneySearch {
	text-align: center;
	.upper {
		.cta {
			margin-top: 1.5rem;
		}
	}
	.lower {
		margin-top: 3rem;
	}
}

.inlineWrapper {
	background: linear-gradient(90deg, $bgColor 0%, $bgColor 50%, #fff 50%, #fff 100%);
	.wrapper {
		display: flex;
	}
	.cell {
		padding: 5rem 0;
	}
	.attorneySearch {
		flex-basis: 445px;
		padding-left: $gutter;
	}
	.copy {
		flex: 1;
		background: $bgColor;
		padding-right: ($gutter * 2);
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.stackedWrapper {
	background: $bgColor;
	padding: 4rem 0;
	.cell + .cell {
		margin-top: 4rem;
	}
}
