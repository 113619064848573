@import './src/styles/_global.scss';
.richText {
	ul,
	ol,
	p {
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	ul,
	ol {
		ul,
		ol,
		li + li {
			margin-top: 8px;
		}
	}

	ol {
		ol {
			list-style: lower-alpha;
			ol {
				list-style: lower-roman;
			}
		}
	}

	ul {
		list-style: none;
		padding: 0 0 0 1em;
		> li {
			position: relative;
			padding-left: 1em;
			&:before {
				content: '•';
				color: $color-gold-dark;
				position: absolute;
				left: 0;
				font-size: 150%;
				line-height: 1;
			}
		}
	}

	strong,
	h4 {
		color: $color-blue-dark;
		font-size: inherit;
		font-weight: 600;
	}

	h4 {
		margin: 1em 0;
	}
}
