@import './src/styles/_global.scss';
$gutter: 15px;
$cols: 1, 2, 3, 4, 5, 6;
$rows: 1, 2, 3, 4, 5, 6;

.list {
	display: flex;
	flex-wrap: wrap;
	margin: (-$gutter * 2) (-$gutter) 0 (-$gutter);

	@media print {
		display: block;
		margin-top: $gutter;
	}

	@each $col in $cols {
		&[col='#{$col}'] {
			.col {
				width: percentage(1 / $col);
			}
		}
	}
	@each $row in $rows {
		&[row='#{$row}'][more='more'] {
			.col:nth-child(n + #{$row + 1}) {
				display: none;
			}
		}
	}

	.col {
		padding: 0 $gutter;
		margin-top: $gutter * 2;
		page-break-inside: avoid;
	}

	.moreWrapper {
		width: 100%;
		text-align: center;
	}

	.more {
		display: inline-flex;
		align-items: center;
		border: none;
		background: none;
		outline: none;
		color: $color-blue;
		font-size: 1.5rem;
		line-height: 1.2;
		font-weight: 700;
		margin-top: 3rem;

		svg {
			fill: $color-blue;
			margin-left: 0.6rem;
			transition: all 0.5s;
		}
	}

	&[more='less'] {
		.more svg {
			transform: rotate(180deg);
		}
	}

	&.stackedLayout {
		flex-direction: column;
		align-items: center;

		.col {
			width: 100%;
		}
	}
}
