@import './src/styles/_global.scss';
.wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #02355c;
	z-index: 9999;
	padding: 1.5rem 0 10rem;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.closeBtn {
	background: none;
	padding: 0;
	border: none;
	width: 34px;
	height: 30px;
	justify-content: center;
	align-items: center;
}

.nav {
	margin: 2rem 0 0;
	padding: 0;
	list-style: none;
	color: #d4dde7;
	li {
		display: block;
	}
	> li + li {
		border-top: 1px solid currentColor;
	}
	> li > a {
		padding: 1.25rem;
		font-weight: 700;
	}
	a {
		display: block;
		color: currentColor;
		text-align: center;
	}
	ul {
		margin: 0 0 1.25rem;
		padding: 0;
		list-style: none;
	}
}
