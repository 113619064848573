@import './src/styles/_global.scss';
.article {
	display: block;
}

.image {
	position: relative;
	margin-bottom: 1rem;

	img {
		display: block;
		width: 100%;
	}

	span {
		position: absolute;
		bottom: 0;
		left: 0;
		color: $color-white;
		background-color: $color-gold-dark;
		display: inline-block;
		padding: 0.5rem 1.7rem 0.5rem 1.1rem;
		font-weight: 700;
		text-transform: uppercase;
	}
}

.headline {
	font-family: $font-condensed;
	font-size: 2.6rem;
	line-height: 1.08;
	font-weight: 500;
	color: $color-blue;
	margin: 0;
	&.large {
		font-size: 3.2rem;
		line-height: 1.187;
	}
}

.excerpt {
	line-height: 1.5;
	margin: 0;
	color: $color-body;
	margin-top: 1rem;
	font-size: 1.8rem;
	p:first-child {
		margin-top: 0;
	}
	p:last-child {
		margin-bottom: 0;
	}
}

.meta {
	margin-top: 0.25rem;
	color: rgba($color-body, 0.5);
	font-size: 1.6rem;
	line-height: 1.5;
}

.source {
	font-style: italic;
	font-weight: 500;
}

.readmore {
	margin-top: 1rem;
}

.horizontal {
	display: flex;
	align-items: flex-start;
	max-width: 100%;

	.image {
		width: 220px;
		max-width: 220px;
		margin-bottom: 0;
		margin-right: 1rem;
		@media print {
			display: none !important;
		}
	}

	.header {
		flex: 1;
	}
}

.noImagePlaceholder {
	padding-bottom: percentage(390 / 760);
	position: relative;
	background: #d2d2d4;
	.inner {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	svg {
		display: block;
		width: 100px;
	}
}
