@import './src/styles/_global.scss';
.wrapper {
	max-width: 300px;
	margin: 0 auto;
	text-align: center;
}

.header {
	text-transform: uppercase;
	color: rgba($color-body, 0.75);
	font-weight: 300;
}

.alphaList {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: 0;
	list-style: none;
	justify-content: center;
	li {
		display: flex;
		flex-basis: percentage(1/9);
		justify-content: center;
		align-items: center;
	}
	button {
		background: none;
		padding: 0;
		border: none;
		color: var(--link-color);
		outline: none;
		cursor: pointer;
	}
	a,
	button {
		flex: 1;
		font-weight: 600;
		padding: 0.25em;
		font-size: 1.8rem;
	}
	.active {
		a,
		button {
			background: $color-blue;
			color: #fff;
		}
	}
}
