@import './src/styles/_global.scss';
.category {
	margin-bottom: 4rem;
	padding-bottom: 4rem;
	border-bottom: 2px solid #75757c;
	overflow: hidden;
}

.body {
	margin-top: 2rem;
}

.image {
	img {
		display: block;
		max-width: 100%;
	}
}

.category.stacked {
	.image {
		margin-bottom: 1.5rem;
	}
	.cta {
		margin-top: 1.5rem;
	}
}

.category.inline {
	.image,
	.blurb {
		flex: 1;
	}
	.image {
		margin-right: 2rem;
		float: left;
	}
	.cta {
		margin-top: 1rem;
	}
}
.category.inline.reverse {
	.image {
		margin-left: 0;
		margin-left: 2rem;
		float: right;
	}
}
