@import './src/styles/_global.scss';
.readMore {
	display: inline-flex;
	align-items: center;
	font-size: 1.5rem;
	line-height: 1.2;
	font-weight: 700;
	font-style: italic;
	text-transform: uppercase;

	@media print {
		display: none !important;
	}

	&:before {
		display: block;
		font-family: $font-condensed;
		font-size: 1.85em;
		font-weight: 300;
		font-style: italic;
		margin-right: 0.5rem;
		content: '/';
	}
}
