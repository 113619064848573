@import './src/styles/_global.scss';
.masthead {
	min-height: 160px;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	background-position: center;
	.inner {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-direction: column;
		min-height: 330px;
		padding: 6rem 0;
		text-align: center;
		position: relative;
		z-index: 2;
		color: #fff;
	}
	.headline {
		color: currentColor;
		font-size: 4.8rem;
		line-height: 1.3;
	}
	.dateStamp {
		margin-top: 1rem;
		font-size: 1.8rem;
	}
	&.large {
		&:before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			content: '';
			background: linear-gradient(rgba(#75757c, 0), rgba(#040404, 0.64));
		}
	}
}

.body {
	.headlineWrapper {
		margin-bottom: 1rem;
	}
	.headline {
		font-size: 24px;
		line-height: 28px;
	}
	.dateStamp {
		color: rgba(#3a3a44, 0.5);
		font-size: 16px;
	}
	.image {
		display: block;
		margin: 0 auto;
	}
}

.label {
	position: absolute;
	left: 0;
	bottom: 0;
	background: $color-gold-dark;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.8rem;
	line-height: 3.3rem;
	white-space: nowrap;
	font-weight: 700;
	padding: 0 1rem;
}

.related {
	margin-top: 3rem;
}
